<template>
    <div>
        <p class="text-secondary mb-1">
            <small>Reviewer</small>
        </p>
        <div>
            <v-select class="review-details-selector form-control bg-light rounded-pill shadow-sm py-0"
                      v-bind:options="paginated"
                      v-bind:filterable="false"
                      v-bind:get-option-key="({ _id }) => _id"
                      v-bind:reduce="({ _id }) => _id"
                      v-bind:get-option-label="({ name, countryCode, phone }) => `${ name } (+${ countryCode } ${ phone })`"
                      v-model="createdBy"
                      v-on:open="onOpen"
                      v-on:close="onClose"
                      v-on:search="query => search = query">
                <template #list-footer>
                    <li ref="load"
                        class="loader px-3"
                        v-show="hasNextPage">
                        <b-spinner class="mr-2"
                                   small>
                        </b-spinner>
                        Loading more options...
                    </li>
                </template>
            </v-select>
        </div>
    </div>
</template>

<script>
import { BSpinner } from "bootstrap-vue";
import vSelect from "vue-select";
export default {
    name: "Reviewer",
    components: {
        BSpinner,
        vSelect,
    },
    props: {
        value: {
            type: String,
        },
    },
    data () {
        return {
            observer: null,
            limit: 10,
            search: "",
        };
    },
    computed: {
        users () {
            return this.$store.getters["users/items"];
        },
        filtered () {
            if (this.search) {
                const filtered = this.users.filter(
                    ({ name, countryCode, phone }) => `${ name } ${ countryCode } ${ phone }`.includes(this.search)
                );
                const result = [...filtered];
                if (filtered.findIndex(({ _id }) => _id === this.createdBy) === -1) {
                    result.unshift(this.users.find(({ _id }) => _id === this.createdBy));
                }
                return result;
            }

            const targetIndex = this.users.findIndex(({ _id }) => _id === this.createdBy);
            const result = [
                ...this.users.slice(0, targetIndex).concat(this.users.slice(targetIndex))
            ];
            if (targetIndex > -1) {
                result.unshift(this.users[targetIndex]);
            }
            return result;
        },
        paginated () {
            return this.filtered.slice(0, this.limit);
        },
        hasNextPage () {
            return this.paginated.length < this.filtered.length;
        },
        createdBy: {
            get () {
                return this.value;
            },
            set (val) {
                if (val) {
                    this.$emit("input", val);
                }
            }
        },
    },
    methods: {
        async onOpen() {
            if (this.hasNextPage) {
                await this.$nextTick();
                this.observer.observe(this.$refs.load);
            }
        },
        onClose() {
            this.observer.disconnect();
        },
        async infiniteScroll([{ isIntersecting, target }]) {
            if (!isIntersecting) {
                return;
            }
            const ul = target.offsetParent;
            const scrollTop = target.offsetParent.scrollTop;
            this.limit += 10;
            await this.$nextTick();
            ul.scrollTop = scrollTop;
        },
    },
    mounted() {
        this.observer = new IntersectionObserver(this.infiniteScroll);
    },
}
</script>

<style lang="scss" scoped>

</style>
